const middleware = {}

middleware['affiliate/auth'] = require('../middleware/affiliate/auth.js')
middleware['affiliate/auth'] = middleware['affiliate/auth'].default || middleware['affiliate/auth']

middleware['affiliate/noAuth'] = require('../middleware/affiliate/noAuth.js')
middleware['affiliate/noAuth'] = middleware['affiliate/noAuth'].default || middleware['affiliate/noAuth']

middleware['agent/auth'] = require('../middleware/agent/auth.js')
middleware['agent/auth'] = middleware['agent/auth'].default || middleware['agent/auth']

middleware['agent/noAuth'] = require('../middleware/agent/noAuth.js')
middleware['agent/noAuth'] = middleware['agent/noAuth'].default || middleware['agent/noAuth']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['bankManagementRedirect'] = require('../middleware/bankManagementRedirect.js')
middleware['bankManagementRedirect'] = middleware['bankManagementRedirect'].default || middleware['bankManagementRedirect']

middleware['noAuth'] = require('../middleware/noAuth.js')
middleware['noAuth'] = middleware['noAuth'].default || middleware['noAuth']

middleware['permissions'] = require('../middleware/permissions.js')
middleware['permissions'] = middleware['permissions'].default || middleware['permissions']

export default middleware
